
import Vue from "vue";
import FileUploader from "@/components/form-components/FileUploader.vue";
import LiveSearch from "@/components/form-components/LiveSearch.vue";
import rules from "@/services/helpers/validation-rules";
import { mask } from "vue-the-mask";
import CreateBeneficiary from "@/modules/orders/components/CreateBeneficiary.vue";
import CommentsPreview from "@/modules/orders/components/CommentsPreview.vue";
import Comments from "@/modules/orders/components/Comments.vue";

export default Vue.extend({
  name: "ClientData",

  components: {
    CommentsPreview,
    CreateBeneficiary,
    LiveSearch,
    FileUploader,
    Comments
  },

  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({})
    },
    errorMessages: {
      type: Object,
      required: false,
      default: () => ({})
    },
    comments: {
      type: Object,
      required: false,
      default: () => ({})
    },
    hover: {
      type: Boolean,
      required: false,
      default: false
    },
    column: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    showDelegate: {
      type: Boolean,
      required: false,
      default: false
    },
    showComments: {
      type: Boolean,
      required: false,
      default: false
    },
    serviceId: {
      type: Number,
      required: false
    }
  },

  directives: { mask },

  data: () => ({
    rules,
    selectedClient: { value: "", text: "" } as null | SelectComponentInterface,
    allDelegates: false as boolean,
    validMPower: false as boolean,
    model: {} as any,
    client: {} as any,
    allDelegatesText: {
      ro: "Toate persoanele împuternicite",
      en: "All authorized persons",
      ru: "Все уполномоченные лица"
    } as any,
    createBeneficiaryModal: false as boolean,
    document: {} as any,
    clientModel: {
      name: "" as string,
      idno: "" as string,
      address: "" as string
    } as any,
    isTrustedDocument: false as boolean,
    forceKey: 0 as number,
    errorMessage: "" as string,
    lastPowerOfAttorney: null as any,
    powerOfAttorneyIsChecked: false as boolean
  }),

  watch: {
    value: {
      immediate: true,
      handler() {
        this.model = this.value;
        this.client = this.value.order.client;

        this.selectedClient = {
          value: this.model.order.client.id,
          text: this.model.order.client.name
        };

        this.document =
          this.model.documents.find(
            (item: any) => item.file_type === "confirm_represent_power"
          ) || {};

        if (this.model?.order?.use_last_power_of_attorney) {
          this.powerOfAttorneyIsChecked = !!this.document?.file;
        }
      }
    },
    document: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit("update", {
          ...this.document,
          file_type: "confirm_represent_power"
        });
      }
    },
    "model.order.power_of_attorney": {
      handler() {
        this.checkDocument();
        if (this.model.order.power_of_attorney === "classic") {
          this.model.order.author_full_name = "";
          this.model.order.author_email = "";
          this.model.order.author_phone = "";
        } else {
          this.model.order.author_full_name = this.value.order.client.name;
          this.model.order.author_email = this.value.order.client.email;
          this.model.order.author_phone = this.value.order.client.phone;
        }
      }
    },
    "model.order.use_last_power_of_attorney": {
      handler() {
        this.checkDocument();
      }
    }
  },

  computed: {
    isClassic() {
      return this.model.order.power_of_attorney === "classic";
    },
    isSelectBeneficiary() {
      return !!this.client.id;
    },
    currentRole() {
      return this.$store.getters["user/currentRole"];
    },
    maxDate() {
      return new Date().toISOString().substr(0, 10);
    },
    isCert() {
      return this.serviceId === 3;
    },
    isAta() {
      return this.serviceId === 1;
    },
    selectedClientNaming() {
      if (this.serviceId === 1) {
        return `${this.$t("orders.form.fields.name_or_idno_ata")}*`;
      }
      return `${this.$t("orders.form.fields.name_or_idno")}*`;
    },
    isJuridical() {
      return this.model.order.client.type === "juridical";
    },
    clientDelegateLabel() {
      if (this.isJuridical) {
        return `${this.$t("orders.form.fields.client_delegate")}*`;
      }
      return this.$t("orders.form.fields.client_delegate");
    },
    radioNameApplicant() {
      if (this.model.order.client.type === "physical" && this.serviceId === 1) {
        return this.$t("orders.form.fields.applicant");
      }
      return this.$t("orders.form.fields.admin");
    },
    radioNameRepresentative() {
      if (
        this.model.order.client.type === "juridical" &&
        this.serviceId === 1
      ) {
        return this.$t("orders.form.fields.representative_classic_ata");
      } else if (this.serviceId === 3) {
        return this.$t("orders.form.fields.representative_classic_ata");
      }
      return this.$t("orders.form.fields.representative_classic");
    },
    clientType() {
      if (this.serviceId === 1) {
        return this.$t("orders.form.fields.client_ata");
      }
      if (this.serviceId === 5) {
        return this.$t("orders.form.fields.client_ata");
      }
      return this.$t("orders.form.fields.expeditor");
    }
  },

  async mounted() {
    if (!this.model.order.client?.id) {
      this.selectedClient = { text: "", value: "" };
    }

    this.allDelegates =
      this.model.order.client_delegate ===
      this.allDelegatesText[this.model.order.language];

    await this.checkPowerOfAttorney();
  },

  methods: {
    deleteDocument() {
      const documentIndex = this.model.documents.findIndex(
        (item: any) => item.file_type === "confirm_represent_power"
      );

      this.document = {};
      if (documentIndex !== -1) {
        this.model.documents.splice(documentIndex, 1);
        this.$forceUpdate();
      }
    },
    checkDocument() {
      if (
        !this.model.order.use_last_power_of_attorney ||
        this.model.order.power_of_attorney !== "classic"
      ) {
        this.deleteDocument();
        return;
      }
      this.document = { ...this.lastPowerOfAttorney };
      this.$forceUpdate();
    },
    canEditField(key: string, field: string): boolean {
      if (!this.model.fields_access) {
        return true;
      }

      if (this.model.fields_access[key]) {
        return false;
      }

      if (this.model.fields_access[key].length) {
        return !this.model.fields_access[key].includes(field);
      }
      return true;
    },
    async checkMpower(): Promise<void> {
      try {
        await this.$API.MPower().checkDocument({
          authorizingIdn: this.client.idno,
          authorizedIdn: this.model.order.mpower_idnp
        });
        this.isTrustedDocument = true;
      } catch (e) {
        this.errorMessage = e.message;
        this.isTrustedDocument = false;
      }
    },
    changeDelegate() {
      if (this.allDelegates) {
        this.model.order.client_delegate = this.allDelegatesText[
          this.model.order.language
        ];
      } else {
        this.model.order.client_delegate = "";
      }
    },
    async reload(): Promise<void> {
      await this.$forceUpdate();
    },
    async searchClient(value: string, key: string): Promise<any> {
      try {
        if (!this.disabled) {
          const params = {
            searchValue: value
          } as any;
          if (this.model.order.service_type_id === 2 || this.serviceId === 2) {
            params.type = "juridical";
          }

          return {
            items: await (this.$API as any)[key]().liveSearchClient(params)
          };
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError");
      }
    },
    async setClient() {
      try {
        if (this.selectedClient?.value) {
          this.client = await this.$API
            .clients()
            .show(Number(this.selectedClient.value));
          this.$emit("setClient", this.client);
          await this.checkPowerOfAttorney();
          this.checkDocument();
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError");
      }
    },
    clearClient() {
      this.client = {
        type: "juridical"
      };
      this.$emit("clearClient");
    },
    changePowerType(type: string) {
      this.model.order.power_of_attorney = type;
    },
    setBeneficiary(event: any) {
      this.selectedClient = {
        value: event.id,
        text: event.name
      };
      this.client = event;
      this.$emit("setClient", event);
    },
    async updateComments(event: any) {
      this.$emit("setComments", event);
      this.changeKey();
    },
    changeKey() {
      this.forceKey = this.forceKey++;
    },
    getErrorMessage(): Array<any> {
      const index = this.model.documents.findIndex(
        (item: any) => item.file_type === "confirm_represent_power"
      );
      if (index !== -1) {
        return this.errorMessages[`documents.${index}.file`];
      }
      return [];
    },
    async checkPowerOfAttorney(): Promise<void> {
      if (!this.model?.order?.client_id) {
        return;
      }
      try {
        const {
          power_of_attorney
        } = await this.$API
          .orders()
          .getLastPowerOfAttorney({ client_id: this.model.order.client_id });

        this.lastPowerOfAttorney = { ...power_of_attorney };

        if (power_of_attorney.file) {
          this.powerOfAttorneyIsChecked = true;
          this.model.order.use_last_power_of_attorney = true;
        }
      } catch (e) {
        this.model.order.use_last_power_of_attorney = false;
      }
    },
    async downloadFile() {
      if (!this.document?.file?.url) {
        return;
      }

      const element = document.createElement("a");
      element.setAttribute("href", this.document?.file?.url);
      element.setAttribute("download", this.document?.file?.name);
      element.setAttribute("target", "_blank");
      element.click();
    }
  }
});
