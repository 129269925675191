var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-5"},[(_vm.docsModel.obligatory)?[_c('div',{staticClass:"text-h6",class:{ 'py-5' : !_vm.isAta }},[_vm._v(" "+_vm._s(_vm.$t("orders.form.fields.request_docs"))+" ")]),(_vm.isAta)?_c('div',{staticClass:"py-3"},[_vm._v(" "+_vm._s(((_vm.$t("orders.form.fields.documents_annotation_ata")) + "*"))+" ")]):_vm._e(),_c('v-row',_vm._l((_vm.obligatoryDocuments),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"12","xs":"12","sm":"6","md":"6","lg":"4","xl":"4"}},[_c('v-card',{staticClass:"active-card-color"},[(item.file_type === 'origin_of_goods')?_c('v-card-text',{staticClass:"d-flex"},[_c('file-uploader',{attrs:{"disabled":_vm.preview || _vm.disabled,"accept":_vm.accept,"error-messages":_vm.getErrorMessage(item.file_type),"label":_vm.isPhysical
                  ? ((_vm.getDocsLabel(
                      'origin_of_goods_physical',
                      'docs_short'
                    )) + "*")
                  : ((_vm.getDocsLabel('origin_of_goods', 'docs_short')) + "*"),"dense":"","truncate-length":"25","rules":[_vm.rules.required()]},on:{"input":_vm.modifyDocuments},model:{value:(item.file),callback:function ($$v) {_vm.$set(item, "file", $$v)},expression:"item.file"}}),_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 mb-3"},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.getDocsLabel("origin_of_goods_physical", "docs_short"))+" ")])])],1):_c('v-card-text',{staticClass:"d-flex"},[_c('file-uploader',{attrs:{"disabled":_vm.preview || _vm.disabled,"accept":_vm.accept,"error-messages":_vm.getErrorMessage(item.file_type),"label":_vm.$t(("orders.form.fields.docs_short." + (item.file_type))) + '*',"dense":"","truncate-length":"25","rules":[_vm.rules.required()]},on:{"input":_vm.modifyDocuments},model:{value:(item.file),callback:function ($$v) {_vm.$set(item, "file", $$v)},expression:"item.file"}}),_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 mb-3"},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t(("orders.form.fields.docs." + (item.file_type))))+" ")])])],1),(_vm.showComments)?_c('comments',{attrs:{"top":"-5px","right":"-5","readonly":_vm.commentsReadonly,"field":("documents.file_type/" + (item.file_type))},on:{"update":_vm.updateComments},model:{value:(_vm.comments[("documents.file_type/" + (item.file_type))]),callback:function ($$v) {_vm.$set(_vm.comments, ("documents.file_type/" + (item.file_type)), $$v)},expression:"comments[`documents.file_type/${item.file_type}`]"}}):_vm._e()],1)],1)}),1)]:_vm._e(),_c('v-row',_vm._l((_vm.docsModel.extra[_vm.area]),function(extraDocs,index){return _c('v-col',{key:index,attrs:{"cols":"12"}},[_c('div',{staticClass:"text-h6 py-5",class:{ 'pt-2 pb-4': _vm.isAta }},[_vm._v(" "+_vm._s(_vm.$t(("orders.form.fields." + extraDocs + "_docs")))+" ")]),(_vm.isAta)?_c('div',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(((_vm.$t("orders.form.fields.documents_annotation", { maxDocumentCounter: _vm.maxDocumentCounter })) + "*"))+" ")]):_vm._e(),_c('v-row',[_vm._l((_vm.extraDocuments[_vm.area][extraDocs]),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"12","xs":"12","sm":"6","md":"6","lg":"4","xl":"4"}},[_c('v-card',{staticClass:"active-card-color"},[_c('v-card-text',{staticStyle:{"display":"grid","grid-template-columns":"90% 30px"}},[_c('file-uploader',{staticClass:"input",attrs:{"disabled":_vm.preview || _vm.disabled,"rules":index === 0 ? _vm.extraRules : [],"accept":_vm.accept,"label":_vm.$t("orders.form.fields.load_file") +
                    "" + (index === 0 && _vm.extraRules ? '*' : ''),"clearable":false,"dense":"","truncate-length":"25","error-messages":_vm.getErrorMessage(item.file_type)},on:{"input":_vm.modifyDocuments},model:{value:(item.file),callback:function ($$v) {_vm.$set(item, "file", $$v)},expression:"item.file"}}),((index !== 0 && !_vm.preview) || !_vm.extraRules)?_c('v-icon',{on:{"click":function($event){return _vm.deleteFile(extraDocs, index)}}},[_vm._v(" mdi-close ")]):_vm._e()],1),(_vm.showComments)?_c('comments',{attrs:{"top":"-5px","right":"-5","readonly":_vm.commentsReadonly,"field":("documents.file_type/" + (item.file_type))},on:{"update":_vm.updateComments},model:{value:(_vm.comments[("documents.file_type/" + (item.file_type))]),callback:function ($$v) {_vm.$set(_vm.comments, ("documents.file_type/" + (item.file_type)), $$v)},expression:"comments[`documents.file_type/${item.file_type}`]"}}):_vm._e()],1)],1)}),(!_vm.preview && !_vm.disabled)?_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","md":"6","lg":"4","xl":"4"}},[_c('v-card',{staticClass:"fill-height full-width",attrs:{"elevation":"0"}},[_c('v-card-text',{staticClass:"d-flex align-center fill-height pa-0 card-border"},[_c('v-btn',{staticClass:"transparent fill-height",attrs:{"depressed":"","block":"","height":"80"},on:{"click":function($event){return _vm.addFile(extraDocs)}}},[_c('v-icon',{staticClass:"font-22",attrs:{"color":"primary"}},[_vm._v(" mdi-plus ")])],1)],1)],1)],1):_vm._e()],2)],1)}),1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }